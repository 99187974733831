<!--

      This view displays the registration page.

 -->

<template>
  <SignInUpPage action="register">
    
    <div class="back-button-area" @click="this.$router.go(-1)">
      <Icon name="foodableArrowBack" />
    </div>

    <div class="signinup-content">
      <div class="headline">Neues Konto erstellen</div>

      <div class="fields">
        <div class="input-field">
          <Icon name="personOutline" class="icon-input" />
          <ion-input @ionChange="setEmail($event)" placeholder="E-Mail" pattern="email" inputmode="email" type="email" data-cy="email"></ion-input>
        </div>
        <div class="input-field">
          <Icon name="lockOpenOutline" class="icon-input" />
          <ion-input ref="pw1" @ionChange="setPassword($event)" placeholder="Passwort (mind. 8 Zeichen)" pattern="password" inputmode="password" type="password" data-cy="register-password-1"></ion-input>
        </div>
        <div class="input-field">
          <Icon name="lockOpenOutline" class="icon-input" />
          <ion-input ref="pw2" v-on:keyup.enter="this.register()" @ionChange="setPassword2($event)" placeholder="Passwort wiederholen" pattern="password" inputmode="password" type="password" data-cy="register-password-2"></ion-input>
        </div>
      </div>

      <div class="btn-primary" type="submit" @click="this.register()" data-cy="submit">Weiter</div>
      <div class="btn-tertiary" type="submit" @click="this.$store.dispatch('writeHelpMail')">Ich brauche Hilfe / habe Feedback</div>
    </div>

  </SignInUpPage>
</template>

<script>
import SignInUpPage from '@/components/SignInUpPage.vue'
import { IonInput } from '@ionic/vue'

export default {
  name: 'register',
  components: {
    SignInUpPage,
    IonInput
  },
  data () {
    return {
      email: null,
      username: null,
      password: null,
      password2: null
    }
  },
  methods: {
    goTo (name) {
      this.$store.dispatch('goTo', name)
    },
    setEmail (event) {
      if (event.target.value !== '') {
        this.email = event.target.value
      }
    },
    setPassword (event) {
      if (event.target.value !== '') {
        this.password = event.target.value
      }
    },
    setPassword2 (event) {
      if (event.target.value !== '') {
        this.password2 = event.target.value
      }
    },
    async register () {
      if (!this.password || !this.password2) {
        this.$store.dispatch('showNotification', { message: 'Bitte fülle beide Passwort-Felder aus.', type: 'Error' })
      }
      if (this.password && this.password2 && this.password != this.password2){
        this.$store.dispatch('showNotification', { message: 'Du hast zwei unterschiedliche Passwörter eingegeben. Bitte versuch es noch einmal.', type: 'Error' })
        this.emptyPasswordFields()
      } else if (this.password && this.password.length < 8) {
        this.$store.dispatch('showNotification', { message: 'Dein Passwort ist zu kurz. Es muss mindestens 8 Zeichen haben.', type: 'Error' })
        this.emptyPasswordFields()
      } else {
        if (this.email && this.password) {
          const details = {
            url: this.$store.state.api.auth.register,
            id: '',
            data: {
              username: this.email,
              email: this.email,
              email2: this.email,
              password: this.password
            },
            method: 'POST'
          }
          this.$store.dispatch('apiSend', details).then( registration => {
            if ( registration && registration.email ) {
              const details2 = {
                url: this.$store.state.api.auth.login,
                id: '',
                data: {
                  grant_type: "password",
                  client_id: process.env.VUE_APP_CLIENT_ID,
                  client_secret: process.env.VUE_APP_CLIENT_SECRET,
                  username: this.email,
                  password: this.password
                },
                method: 'POST'
              }
              this.$store.dispatch('apiSend', details2).then(auth => {
                if ( auth && auth.access_token ) {
                  this.$store.dispatch('save', { key: 'accesstoken', value: auth.access_token }).then( () => {
                    this.$store.dispatch('runThisUponSuccessfulRegistration')
                  })
                  this.$store.dispatch('save', { key: 'refreshtoken', value: auth.refresh_token })
                } else if ( auth ) {
                  this.$store.dispatch('showNotification', { message: 'E-Mail oder Passwort ist falsch. Bitte versuch dich noch einmal anzumelden.', type: 'Error' })
                } else {
                  this.$store.dispatch('showNotification', { message: 'Ein Problem ist aufgetreten. Bitte versuche dich erneut einzuloggen.', type: 'Error' })
                }
              })
            } else if ( registration && registration.username && registration.username[0].includes("already exists") ) {
              this.$store.dispatch('showNotification', { message: 'Diese E-Mail-Adresse ist bereits registriert. Klicke auf "Anmelden" oder "Passwort vergessen". Oder registriere dich bitte mit einer anderen E-Mail-Adresse.', type: 'Error' })
            } else if ( registration && registration.email2 && registration.email2[0].includes("ist bereits registriert") ) {
              this.$store.dispatch('showNotification', { message: 'Diese E-Mail-Adresse ist bereits registriert. Klicke auf "Anmelden" oder "Passwort vergessen". Oder registriere dich bitte mit einer anderen E-Mail-Adresse.', type: 'Error' })
            }
          })
        } else {
          this.$store.dispatch('showNotification', { message: 'Bitte E-Mail und Passwort eingeben.', type: 'Error' })
        }
      }
    },
    emptyPasswordFields () {
      this.$refs.pw1.value = ''
      this.$refs.pw2.value = ''
    }
  }
}
</script>
